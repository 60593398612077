import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const IndexPage = props => {
  const {
    title,
    node_locale: locale,
    body: { json }
  } = props.data.contentfulPage

  return (
    <Layout data={props.data} location={props.location} locale={locale}>
      <SEO title={title} />
      {documentToReactComponents(json)}
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query {
    contentfulPage(slug: { eq: "home" }) {
      title
      node_locale
      body {
        json
      }
    }
  }
`
